<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados de acesso</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"
          v-model="formData.strNome"
          name="strNome"
          label="Nome"
          rules="required"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
          v-model="formData.strCpf"
          name="strCpf"
          label="CPF"
          rules="required|cpf"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strLogin"
          name="strLogin"
          label="Login"
          rules="required"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strEmail"
          name="strEmail"
          label="Email"
          rules="required|email"
          :disabled="!formData.boolAtivo"
        />


        <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.boolAtivo"
          name="boolAtivo"
          label="Usuario Ativo?"
        />
        <ed-input-switch
          v-if="formData.intId>0"
          class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          v-model="formData.boolResetarSenha"
          name="boolResetarSenha"
          label="Resetar senha desse usuário"
          :disabled="!formData.boolAtivo"
        />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Tipo de Acesso</span>

      <div class="row">
        <ed-input-tipo
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.intTipoUsuarioId"
          label="Tipo de Usuário"
          name="intTipoUsuarioId"
          tipo="1"
          rules="required"
          :disabled="!formData.boolAtivo || [2,3].indexOf(this.$root.$session.usuario.intTipoUsuarioId) == -1"
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.arrayCadastroGeralEmpresa"
          label="Vincular empresa nesse usuário"
          name="arrayCadastroGeralEmpresa"
          item-text="strNome"
          placeholder="Todas as empresas"
          route="Cadastro/CadastroGeral"
          :filters="{
            intTipoCadastroGeralId: 58,
          }"
          :rules="([5,6,7].indexOf(formData.intTipoUsuarioId) != -1 ? 'required|array' : '')"
          :initializeItems="formData.arrayCadastroGeralEmpresa.length"
          multiple
          clearable
        />

        <ed-input-auto-complete
          v-if="[318].indexOf(formData.intTipoUsuarioId) != -1"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.intProfissionald"
          label="Vincular profissional nesse usuário"
          name="intProfissionald"
          item-text="strNome"
          route="Cadastro/Profissional"
          :filters="{
            intTipoProfissionalId: 269,
          }"
          rules="required"
        />

        <ed-input-select
          v-if="$root.$session.filiais.length>1"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.filiais"
          label="Filial"
          name="filiais"
          rules="required"
          :items="$root.$session.filiais"
          multiple
        />
        
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Permissões de Acesso</span>
      <permissao :formData="formData" />
    </div>

    <div  v-if="formData.intId> 0 && !formData.boolAtivo"  class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <ed-alert type="warning">Esse usuário está INATIVO</ed-alert>
    </div>
  </div>
</template>

<script>
import Permissao from "./permissao.vue";

import {
  EdInputText,
  EdAlert,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputTime,
  EdInputDate,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdInputSelect,
    EdAlert,
    EdInputTipo,
    EdInputSwitch,
    EdInputAutoComplete,
    Permissao,
  },
  provide() {
    return {};
  },
  mounted() {
  },
  created() {},
  data() {
    return {
      bind:{
      }
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {},
  methods: {
    setCnae(item) {
      if (item?.strCodigo) {
        this.formData.intGrauRisco = item.intGrauRisco;
      }
    },
  },
};
</script>
