<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-log-acesso" v-if="formData.intId > 0">
        Logs de acesso
        <i :class="$utilidade.getIcone('historico') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral :formData="formData" v-show="strTab == 'tab-cadastro'" />
      <log-acesso :formData="formData" v-show="strTab == 'tab-log-acesso'" />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral.vue";
import LogAcesso from "./partials/log_acesso.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intUsuarioId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    LogAcesso,
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        boolAtivo: 1,
        boolTodasEmpresa: 1,
        boolResetarSenha: 0,
        intTipoUsuarioId: Number(this.$root.$session.usuario.intTipoUsuarioId),
        arrayCadastroGeralEmpresa: [],
        filiais:[this.$root.$session.filial ? this.$root.$session.filial.id : 0],
        objPermissao: {},
      },
      bind: {
        arrayModulo: [],
        arrayMenu: [],
      },
    };
  },
  watch: {
    intUsuarioId() {
      this.initialize();
    },
  },
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.intUsuarioId
          ? this.$root.$request.get("Cadastro/Usuario", {
              intId: this.intUsuarioId,
            })
          : null,
      ]).then(([objUsuario]) => {
        this.$root.$dialog.loading(false);

        if (objUsuario && objUsuario.status == 200 && objUsuario.result) {
          let arrayFilial = objUsuario.result.filiais;

          objUsuario.result.filiais = []

          arrayFilial.forEach(element => {
            objUsuario.result.filiais.push(element.intFilialId)
          });

          this.formData = Object.assign(objUsuario.result, {
            boolResetarSenha: 0,
            boolTodasEmpresa: !this.formData.arrayCadastroGeralEmpresa.length ? 1 : 0,
          });
        }
      });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request.post("Cadastro/Usuario", this.formData).then((objResponse) => {
        this.$root.$dialog.loading(false);

        if (objResponse.status == 200) {
          if (this.$route.name != "cadastro.usuario.edit") {
            this.$router.push({
              name: "cadastro.usuario.edit",
              params: {
                intId: objResponse.result.intId,
              },
            });
          } else {
            this.initialize();
          }
        }
      });
    },
  },
};
</script>
