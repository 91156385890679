<template>
  <div>
      <ed-usuario :intUsuarioId="$route.params.intId"  :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdUsuario from "@/components/cadastro/usuario/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdUsuario },
  mounted() {
    if(!this.$route.params.intId){
      this.$router.push({ name: "cadastro.usuario.register" });
    }
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>