<template>
  <div class="row">


  </div>
</template>

<script>
import {
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputSwitch,
  },
  provide() {
    return {};
  },
  mounted() {
  },
  created() {},
  data() {
    return {
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
      },
      deep: true,
    },
  },
  methods: {
  },
};
</script>
