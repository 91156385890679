<template>
  <div class="row mt-2">
    <v-expansion-panels
      v-if="true"
      :accordion="true"
      :disabled="!formData.boolAtivo"
    >
      <v-expansion-panel
        v-for="(objClienteModulo, index) in $root.$session.cliente.modulos"
        :key="index"
      >
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <div class="row">
              <div class="col-10 pa-0 text-uppercase" :key="'modulo-' + index">
                <div class="pa-2">
                  <b>Módulo {{ objClienteModulo.modulo.strNome }}</b>
                </div>
              </div>
            </div>
            <div class="col-2 pa-0" style="font-size: 9pt; color: grey">
              {{ getTotalModulo(objClienteModulo.modulo.intId) }} acesso(s)
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(menu, j) in $root.$session.usuario.menu">
            <div
              :key="'menu-' + index + j"
              v-if="menu.intModuloId == objClienteModulo.modulo.intId"
            >
              <!--PERMISSAO MENU -->
              <ed-input-switch
                v-model="formData.objPermissao[menu.strRota]"
                name="strCodigoPermissao"
                :label="menu.strNome"
                inline
                @click="checkPermissaoFilho([menu])"
              />

              <!--PERMISSAO SUBMENU -->
              <div
                v-for="(sub_menu, k) in menu.sub_menu"
                :key="'sub-menu-' + k"
                class="pl-2 pb-2"
              >
                <ed-input-switch
                  v-model="formData.objPermissao[sub_menu.strRota]"
                  name="strCodigoPermissao"
                  :label="sub_menu.strNome"
                  @click="checkPermissaoFilho([sub_menu])"
                  inline
                />

                <div
                  v-for="(sub_menu2, k) in sub_menu.sub_menu"
                  :key="'sub-menu-2-' + k"
                  class="pl-2 pb-2"
                >
                  <ed-input-switch
                    v-model="formData.objPermissao[sub_menu2.strRota]"
                    name="strCodigoPermissao"
                    :label="sub_menu2.strNome"
                    @click="checkPermissaoFilho([sub_menu2])"
                    inline
                  />
                </div>
              </div>
            </div>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { EdInputSwitch } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputSwitch,
  },
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  methods: {
    getTotalModulo(intModuloId) {
      let intTotal = this.getTotalModuloPorMenu(
        this.$root.$session.usuario.menu,
        intModuloId
      );

      return intTotal;
    },

    getTotalModuloPorMenu(arrayMenu, intModuloId) {
      let intTotal = 0;
      let self = this;

      arrayMenu.forEach((objMenu) => {
        if (objMenu.intModuloId == intModuloId) {
          //MENU
          if (self.formData.objPermissao[objMenu.strRota]) {
            intTotal++;
          }

          if (objMenu.sub_menu.length) {
            intTotal += self.getTotalModuloPorMenu(
              objMenu.sub_menu,
              intModuloId
            );
          }
        }
      });

      return intTotal;
    },

    checkPermissaoFilho(arrayMenu, boolPermissao) {
      arrayMenu.forEach((objMenu) => {
        if (boolPermissao !== undefined) {
          this.formData.objPermissao[objMenu.strRota] = boolPermissao;
        }

        if (objMenu.sub_menu.length) {
          this.checkPermissaoFilho(
            objMenu.sub_menu,
            this.formData.objPermissao[objMenu.strRota]
          );
        }
      });
    },
  },
};
</script>
